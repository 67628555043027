@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

$background-color: #323437;
$primary-color: #e2b714;
$caret-color: #e2b714;
$secondary-color: #646669;
$secondary-alt-color: #2c2e31;
$text-color: #d1d0c5;

$text-font: 'Roboto Mono', 'monospace';
$logo-font: 'Lexend Deca', sans-serif;

$correct-color: #0f0;
$wrong-color: #f00;
