@import './variables';

.app {
    display: grid;
    grid-template-columns: auto minmax(0, 1250px) auto;
    min-height: 100vh;
    background-color: $background-color; }

.appContainer {
    display: flex;
    flex-direction: column;
    padding: 2em; }
