@import '../../variables';

.configbarItem {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.75rem 0.75rem;
  font-size: 0.75rem;
  color: $secondary-color;
  font-family: $text-font;
  cursor: pointer;
  transition-duration: 150ms;
  user-select: none;

  .itemIcon {
    margin-top: 4px; }

  &:hover {
    color: $text-color; } }

.configbarItemActive {
  color: $primary-color; }

@media screen and ( max-width: 900px ) {
  .configbarItem {
    padding: 0.75rem 0.4rem; } }

@media screen and ( max-width: 800px ) {
  .configbarItem {
    padding: 0.5rem 1.25rem; } }
