@import '../../variables';

.configbarContainer {
    display: flex;
    justify-content: center;
    margin-top: 2em; }

.configbar {
    display: flex;
    background-color: $secondary-alt-color;
    border-radius: 0.5rem; }

.configbarMobile {
    display: none; }

.divider {
    background-color: $background-color;
    border-radius: 0.5rem;
    width: 0.25rem;
    height: auto;
    margin: 0.5em 0; }

@media screen and ( max-width: 900px ) {
    .configbar {
        padding: 0 0.5rem; }

    .configbarItem {
        padding: 0.75rem 0.4rem; } }

@media screen and ( max-width: 800px ) {
    .configbar {
        display: none; }

    .configbarMobile {
        display: flex;
        background-color: $secondary-alt-color;
        border-radius: 0.5rem; }

    .configbarItem {
        padding: 0.5rem 1.25rem; } }
