@import '../../variables';

.testContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    flex-grow: 1;
    justify-content: center; }

.timer {
    font-family: $text-font;
    font-size: 1.5rem;
    color: $primary-color; }

.wordWrapper {
    color: $secondary-color;
    line-height: 1em;
    font-size: 1.5rem;
    font-family: $text-font;
    max-height: 123px;
    overflow: hidden; }

.word {
    display: inline-block;
    position: relative;
    margin: 0.4em 0.25em;
    user-select: none; }

.extraWord {
    color: $wrong-color; }

.wrongWord {
    text-decoration: underline;
    text-decoration-color: $wrong-color; }

.caret {
    position: absolute;
    background: transparent;
    width: 2px;
    height: 100%;
    animation: blink 1s infinite;
    transition: left 150ms; }

.correct {
    color: $correct-color; }

.wrong {
    color: $wrong-color; }

.buttonContainer {
    display: flex;
    justify-content: center;
    padding-top: 1.5em; }

.restartButton {
    color: $secondary-color;
    cursor: pointer;
    font-size: 1.15rem;
    transition-duration: 150ms;
    transition: transform ease-in 150ms;
    position: relative;

    &:hover {
        color: $text-color;

        &::after {
            transform: scale(1);
            top: 150%; } }

    &::after {
        content: attr(data-tooltip);
        display: block;
        position: absolute;
        background-color: rgba(black, .7);
        padding: 0.5em 1em;
        color: white;
        font-family: $text-font;
        font-size: 1rem;
        top: 0;
        left: -325%;
        white-space: nowrap;
        transform: scale(0);
        transition: transform ease-out 150ms, top ease-out 150ms; } }

@keyframes blink {
    to {
        background: $primary-color; } }
