@import '../../../variables';

.configbarItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    color: $text-color;
    border-radius: 0.5em;
    background-color: $secondary-alt-color;
    font-family: $text-font;
    transition-duration: 150ms;
    user-select: none; }

.configbarItemActive {
    background-color: $primary-color;
    color: $background-color; }
